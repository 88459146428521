import { Typography } from '@material-ui/core';
import { useMemo } from 'react';
import { ReferralRisk } from '../../../lib/types/referralRisk';
import RiskScores from './badgeContent/riskScores';
import RiskBadge from './riskBadge';

type Props = {
  risk: ReferralRisk;
};

function ScreenBadge({ risk }: Props) {
  const { riskLevel, secondaryScores } = risk;

  const popoverContent = useMemo(() => {
    if (!secondaryScores?.length) {
      return null;
    }

    return (
      <>
        <Typography variant="h4">Explanation</Typography>
        <RiskScores riskScores={secondaryScores} />
      </>
    );
  }, [secondaryScores]);

  // Don't display badge if there are no recommendations
  if (!risk.secondaryScores) {
    return null;
  }

  return (
    <RiskBadge riskLevel={riskLevel} suggestion={risk.suggestion} popoverContent={popoverContent} />
  );
}

export default ScreenBadge;
