import { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { handleErrorInFunctional } from '../../../components/errorBoundary/errorHandlingUtils';
import RoutePath from '../../../components/routePaths';
import { useFeatures } from '../../../hooks';
import useCheckFeatures from '../../../hooks/useCheckFeatures';
import Feature from '../../../lib/types/feature';
import { StoreContext } from '../../../store';
import useDispatchFilter from '../useDispatchFilter';
import ReferralList from './referralList';
import { columnsDefault } from './referralListCommon';

function PredictionsListPage() {
  const navigate = useNavigate();
  const [{ workflowFilterOptions }] = useContext(StoreContext);
  const { dispatchReferralListFilters } = useDispatchFilter();
  const checkMLFeature = useCheckFeatures(Feature.ML_SCHEDULING);

  const { featureList } = useFeatures();
  const [errorStatus, setErrorStatus] = useState(null);

  useEffect(() => {
    checkMLFeature().then((enabled) => {
      if (!enabled) {
        navigate(RoutePath.FeatureAccessDisabled);
      }
    });
  }, [featureList]);

  handleErrorInFunctional(errorStatus, setErrorStatus);

  return (
    <ReferralList
      columns={columnsDefault}
      filterOptions={workflowFilterOptions}
      onFilterChange={dispatchReferralListFilters}
      featureList={featureList}
      referralDetailsURI="prediction"
    />
  );
}

export default PredictionsListPage;
