import { makeStyles, Tab } from '@material-ui/core';
import { TabList } from '@material-ui/lab';
import { ClinicianStatus } from '../../lib/types/clinicianStatus';
import { ReferralAlert } from '../../lib/types/referralAlerts';
import { ReferralStatus } from '../../lib/types/referralStatus';
import { TestStatus } from '../../lib/types/testStatus';
import { ColumnId } from './referralTableColumn';

type TabOptionFilter = {
  workflow: ReferralStatus[];
  test: TestStatus[];
  clinician: ClinicianStatus[];
  alerts: ReferralAlert[];
};

export type FilterOptionKey = keyof TabOptionFilter;

export enum TabOptionKey {
  Todo = 'TODO',
  InProgress = 'IN_PROGRESS',
  Ready = 'READY',
  PostOp = 'POST_OP',
  All = 'ALL',
  AllFilters = 'ALL_FILTERS',
}

export type TabOptionData = {
  label: string;
  filter: Partial<TabOptionFilter>;
  columns: ColumnId[];
};

export const tabOptionKeyNames: Record<FilterOptionKey, string> = {
  workflow: 'Workflow',
  test: 'Test',
  clinician: 'Clinician',
  alerts: 'Attention',
};

const commonColumns: ColumnId[] = [
  'name',
  'ur',
  'surgicalUnitName',
  'procedureName',
  'state',
  'surgeryDate',
  'triageCategory',
  'createdAt',
];

const mainColumns: ColumnId[] = [
  ...commonColumns,
  'complexityMetsRisk',
  'asaScore',
  'prehab',
  'riskLevel',
];
const allReferralStatuses: ReferralStatus[] = Object.values(ReferralStatus);
const allTestStatuses: TestStatus[] = Object.values(TestStatus);
const allClinicianStatuses: ClinicianStatus[] = Object.values(ClinicianStatus);
const allAlertStatuses: ReferralAlert[] = Object.values(ReferralAlert);
const allColumns: ColumnId[] = [...mainColumns, 'rehab', 'followUp', 'postOp'];

export const tabOption: Record<TabOptionKey, TabOptionData> = {
  TODO: {
    label: 'Waiting list',
    filter: {
      workflow: [
        ReferralStatus.WaitingList,
        ReferralStatus.DetailsRequested,
        ReferralStatus.HQSent,
      ],
      alerts: [
        ReferralAlert.EmailInvalid,
        ReferralAlert.EmailNotReceived,
        ReferralAlert.EmailOptOut,
      ],
    },
    columns: [...commonColumns, 'followUp'],
  },
  IN_PROGRESS: {
    label: 'Needs work',
    filter: {
      workflow: [ReferralStatus.HQReceived, ReferralStatus.AppointmentBooked],
      test: [TestStatus.Updated, TestStatus.Ordered, TestStatus.Received],
      clinician: [ClinicianStatus.Requested, ClinicianStatus.Received],
      alerts: [ReferralAlert.Medication],
    },
    columns: [
      ...mainColumns,
      'rehab',
      'followUp',
      'endoscopySuitable',
      'privateSuitable',
      'dayCase',
    ],
  },
  READY: {
    label: 'Ready for surgery',
    filter: {
      workflow: [ReferralStatus.ReadyForSurgery, ReferralStatus.BookedForSurgery],
      alerts: [ReferralAlert.AdviceUnsent],
    },
    columns: [...commonColumns, 'rehab', 'riskLevel'],
  },
  POST_OP: {
    label: 'Post-op',
    filter: {
      workflow: [
        ReferralStatus.ReadyForSurgery,
        ReferralStatus.BookedForSurgery,
        ReferralStatus.PostOpHQReceived,
        ReferralStatus.PostOpHQSent,
      ],
    },
    columns: [...commonColumns, 'postOp'],
  },
  ALL: {
    label: 'All patients',
    filter: {
      workflow: allReferralStatuses,
      test: [TestStatus.Updated, TestStatus.Ordered, TestStatus.Received],
      clinician: [ClinicianStatus.Requested, ClinicianStatus.Received],
    },
    columns: mainColumns,
  },
  ALL_FILTERS: {
    label: 'All filters',
    filter: {
      workflow: allReferralStatuses,
      test: allTestStatuses,
      clinician: allClinicianStatuses,
      alerts: allAlertStatuses,
    },
    columns: allColumns,
  },
};

const useStyles = makeStyles((theme) => ({
  tab: {
    minWidth: 0,
    [theme.breakpoints.up('sm')]: {
      minWidth: 0,
    },
    margin: 0,
    borderRadius: 0,
    padding: 20,
    borderBottom: '3px solid transparent',
    transition: 'none',
    '& span': {
      color: '#A0D2F8',
      fontSize: 15,
    },
    '&:hover': {
      color: '#2B99F0',
      opacity: 1,
    },
    '&.Mui-selected': {
      backgroundColor: '#fff',
      borderBottom: '3px solid #2B99F0',
    },
    '&.Mui-selected span': {
      color: '#2B99F0',
    },
    '&.Mui-focusVisible': {
      backgroundColor: '#fff',
    },
    '&.MuiTab-root': {
      '&:first-child': {
        borderTopLeftRadius: '12px',
      },
      '&:last-child': {
        borderTopRightRadius: '12px',
      },
      '&.Mui-selected:hover': {
        backgroundColor: '#f7f7f7',
      },
    },
  },
}));

type TabSelectProps = {
  onTabChange: (value: TabOptionKey) => void;
};

function TabSelector({ onTabChange }: Readonly<TabSelectProps>) {
  const classes = useStyles();

  const handleTabChange = (_: object, value: TabOptionKey) => onTabChange(value);

  return (
    <TabList onChange={handleTabChange} variant="fullWidth">
      {Object.entries(tabOption)
        .filter(([key, _value]) => key !== TabOptionKey.AllFilters)
        .map(([key, value]) => (
          <Tab key={`${key}_tabOption`} className={classes.tab} label={value.label} value={key} />
        ))}
    </TabList>
  );
}

export default TabSelector;
