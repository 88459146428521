import { Component } from 'preact';
import 'preact/debug';
import 'preact/devtools';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import Helmet from 'react-helmet';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import AuthGuard from '../auth/authGuard';
import CallbackPage from '../auth/callbackPage';
import InvitePage from '../auth/invitePage';
import LoginPage from '../auth/loginPage';
import POPAuthProvider from '../auth/popAuthProvider';
import RoleName, { RoleClinician } from '../lib/types/roleName';
import AdviceSettingsPage from '../routes/adviceSettingsPage';
import BusinessAnalytics from '../routes/businessAnalytics';
import CancelReminder from '../routes/cancelReminder';
import DynamicQuestionnaire from '../routes/dynamicQuestionnaire';
import FeatureAccessDenied from '../routes/featureAccessDenied';
import FeatureSwitchSettings from '../routes/featureSwitchSettings';
import GpReferral from '../routes/gpReferral';
import GpReferralSuccess from '../routes/gpReferral/gpReferralSuccess';
import Home from '../routes/home';
import NotFound from '../routes/notFound';
import NotificationSettings from '../routes/notificationSettings';
import Overview from '../routes/overview';
import PatientAdvicePreview from '../routes/patientAdvicePreview';
import QuestionnaireSettings from '../routes/questionnaireSettings';
import ReferralDetails from '../routes/referralDetails';
import {
  EndoscopyListPage,
  PatientListPage,
  PredictionsListPage,
} from '../routes/referralList/pages';
import ReferralListSettings from '../routes/referralListSettings';
import ReferralSettings from '../routes/referralSettings';
import RemindersSettings from '../routes/remindersSettings';
import RiskAlertSettings from '../routes/riskAlertSettings';
import RiskPredictionDetailsPage from '../routes/riskPredictionDetails';
import RiskPredictionSettings from '../routes/riskPredictionSettings';
import UserList from '../routes/userList';
import { StoreProvider } from '../store';
import AlertPop from './alertPop';
import awsRum from './awsRum';
import ErrorBoundary from './errorBoundary';
import Footer from './footer';
import Navbar from './navbar';
import PatientInfoForm from './patientInfoForm';
import RoutePath from './routePaths';
import ThemeProvider from './themeProvider';

export default class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      title: null,
    };
  }

  // eslint-disable-next-line class-methods-use-this
  onUrlChange(url) {
    const urlToRum = url.replace(
      /\b[0-9a-f]{8}\b-[0-9a-f]{4}-[0-9a-f]{4}-[0-9a-f]{4}-\b[0-9a-f]{12}\b/gi,
      '[GUID]',
    );

    awsRum.recordPageView(urlToRum);
  }

  setTitle(newTitle) {
    const { title } = this.state;
    if (title !== newTitle) {
      this.setState({ title: newTitle });
    }
  }

  render() {
    return (
      <BrowserRouter>
        <StoreProvider>
          <ThemeProvider>
            <ErrorBoundary>
              <POPAuthProvider>
                <AlertPop />
                <Helmet
                  titleTemplate="%s | Atidia POP"
                  onChangeClientState={(newState) => this.setTitle(newState.title)}
                />

                {/* Force footer to bottom before content loads */}
                <div style={{ minHeight: 'calc(100vh - 64px)' }}>
                  <Navbar />

                  <Routes onChange={(e) => this.onUrlChange(e.url)}>
                    {/* Root redirects based on role */}
                    <Route element={<AuthGuard component={Home} />} path="/" />

                    {/* Public pages not requiring auth */}
                    <Route element={<PatientInfoForm />} path="/patient-info/:formKey" />
                    <Route element={<DynamicQuestionnaire />} path="/healthquestionnaire/:theKey" />
                    <Route element={<LoginPage />} path="/login" />
                    <Route element={<CancelReminder />} path="/cancelReminder/:referralUuid" />

                    {/* Top level pages */}
                    <Route element={<AuthGuard component={InvitePage} />} path={RoutePath.Invite} />
                    <Route
                      element={<AuthGuard component={Overview} restrictTo={RoleName.Nurse} />}
                      path={RoutePath.Overview}
                    />
                    <Route
                      element={
                        <AuthGuard component={BusinessAnalytics} restrictTo={RoleName.Admin} />
                      }
                      path={RoutePath.BusinessAnalytics}
                    />

                    {/* Referral list pages */}
                    <Route
                      element={<AuthGuard component={PatientListPage} restrictTo={RoleClinician} />}
                      path={RoutePath.ReferralListPatients}
                    />
                    <Route
                      element={
                        <AuthGuard component={EndoscopyListPage} restrictTo={RoleClinician} />
                      }
                      path={RoutePath.ReferralListEndoscopy}
                    />

                    <Route
                      element={
                        <AuthGuard component={PredictionsListPage} restrictTo={RoleClinician} />
                      }
                      path={RoutePath.ReferralListPredictions}
                    />

                    <Route
                      element={<AuthGuard component={GpReferral} restrictTo={RoleClinician} />}
                      path="/referral/new/"
                    />

                    {/* Menu pages */}
                    <Route
                      element={<AuthGuard component={UserList} restrictTo={RoleName.Admin} />}
                      path="/users/list"
                    />
                    <Route
                      element={
                        <AuthGuard component={NotificationSettings} restrictTo={RoleName.Admin} />
                      }
                      path="/manage/notifications"
                    />

                    <Route
                      element={
                        <AuthGuard component={ReferralSettings} restrictTo={RoleName.Admin} />
                      }
                      path="/manage/referral"
                    />

                    <Route
                      element={
                        <AuthGuard component={RiskAlertSettings} restrictTo={RoleName.Admin} />
                      }
                      path="/manage/alerts-risks"
                    />

                    <Route
                      element={
                        <AuthGuard component={RemindersSettings} restrictTo={RoleName.Admin} />
                      }
                      path="/manage/reminders"
                    />

                    <Route
                      element={
                        <AuthGuard component={RiskPredictionSettings} restrictTo={RoleName.Admin} />
                      }
                      path="/manage/predictions"
                    />

                    <Route
                      element={
                        <AuthGuard component={ReferralListSettings} restrictTo={RoleName.Admin} />
                      }
                      path="/manage/referralList"
                    />

                    <Route
                      element={
                        <AuthGuard
                          component={FeatureSwitchSettings}
                          restrictTo={RoleName.SuperAdmin}
                        />
                      }
                      path="/manage/featureSwitches"
                    />

                    <Route
                      element={
                        <AuthGuard component={QuestionnaireSettings} restrictTo={RoleName.Admin} />
                      }
                      path="/manage/hq-settings"
                    />

                    <Route
                      element={
                        <AuthGuard component={AdviceSettingsPage} restrictTo={RoleName.Admin} />
                      }
                      path="manage/advice"
                    />

                    {/* Nested pages */}
                    <Route
                      element={<AuthGuard component={ReferralDetails} restrictTo={RoleClinician} />}
                      path="/referral/:referralUuid"
                    />

                    <Route
                      element={
                        <AuthGuard component={PatientAdvicePreview} restrictTo={RoleClinician} />
                      }
                      path="/referral/:referralUuid/advice/:mode/markTestsAsReceived/:markTestsAsReceived"
                    />

                    <Route
                      element={<AuthGuard component={GpReferralSuccess} />}
                      path="/referral/new/submitted/:hqKey/:optOut?"
                    />

                    <Route
                      element={
                        <AuthGuard
                          component={RiskPredictionDetailsPage}
                          restrictTo={RoleName.Nurse}
                        />
                      }
                      path="/prediction/:referralUuid"
                    />

                    {/* Fallback pages */}
                    <Route
                      element={<FeatureAccessDenied />}
                      path={RoutePath.FeatureAccessDisabled}
                    />
                    <Route element={<CallbackPage />} path="/callback" />
                    <Route element={<NotFound />} path="*" />
                  </Routes>
                </div>
                <Footer />
              </POPAuthProvider>
            </ErrorBoundary>
          </ThemeProvider>
        </StoreProvider>
      </BrowserRouter>
    );
  }
}
