import AlertInfo from '../lib/types/alertInfo';
import { ClinicianStatus } from '../lib/types/clinicianStatus';
import MedicationStatement from '../lib/types/medicationStatement';
import PatientAllergy from '../lib/types/patientAllergy';
import { PredictionRecord } from '../lib/types/predictionRecord';
import { ReferralRisk } from '../lib/types/referralRisk';
import { ReferralStatus } from '../lib/types/referralStatus';
import { ReferralTest } from '../lib/types/referralTest';
import { TestStatus } from '../lib/types/testStatus';
import { TimelineNote } from '../lib/types/timelineNote';
import PatientSurgery from '../routes/referralDetails/systemsReview/types';
import { TabOptionKey } from '../routes/referralList/tabSelector';
import { FilterParams, RibbonFilterMap } from '../routes/referralList/types';
import { CompletedHQ } from '../services/useHQReviewService';
import { IncompleteQuestionnaireKey } from '../services/useReferralService';

export type StateType = {
  /** Right-to-left locale */
  rtl: boolean;
  localeCode: 'en' | 'ar';
  pendingAppointment: any;
  pendingCancellationReason: string;
  currentState: any;
  patientQuestionStatus: any;
  testStatus: TestStatus | null;
  drReviewStatus: ClinicianStatus | null;
  hasAttachedFiles: boolean;
  attachedFiles: any;
  referralData: any;
  reviewData: Record<string, any> | null;
  testData: ReferralTest[] | null;
  medsData: MedicationStatement[] | null;
  completedHqs: CompletedHQ[];
  incompleteHQs: IncompleteQuestionnaireKey[];
  patientAllergies: PatientAllergy[];
  patientSurgeries: PatientSurgery[];
  patientNotes: TimelineNote[];
  dateRange: any;
  reviewTab: string;
  selectedTab: TabOptionKey;
  ribbonFilters: RibbonFilterMap;
  workflowFilterOptions: FilterParams;
  predictionFilterOptions: FilterParams;
  totalReferralCount: number;
  selectedReferrals: any[];
  // patient referral alerts and risks
  alerts: AlertInfo[];
  risks: ReferralRisk[];
  predictions: PredictionRecord[];
  // app errors/info/alert
  alert: {
    error: boolean;
    open: boolean;
    message: string;
  };
};

const initialState: StateType = {
  rtl: false,
  localeCode: 'en',
  pendingAppointment: null,
  pendingCancellationReason: '',
  currentState: null,
  patientQuestionStatus: null,
  testStatus: null,
  drReviewStatus: null,
  hasAttachedFiles: false,
  attachedFiles: {},
  referralData: {},
  reviewData: {},
  testData: null,
  medsData: null,
  completedHqs: [],
  incompleteHQs: [],
  patientAllergies: [],
  patientSurgeries: [],
  patientNotes: [],
  dateRange: {},
  reviewTab: '1',
  selectedTab: TabOptionKey.InProgress,
  ribbonFilters: new Map(),
  workflowFilterOptions: {
    page: 0,
    rowsPerPage: 10,
    order: 'asc',
    filter: {},
  },
  predictionFilterOptions: {
    page: 0,
    rowsPerPage: 10,
    orderBy: 'name',
    order: 'asc',
    filter: {
      state: [
        ReferralStatus.HQReceived,
        ReferralStatus.AppointmentBooked,
        ReferralStatus.ReadyForSurgery,
        ReferralStatus.BookedForSurgery,
      ],
    },
  },
  totalReferralCount: 0,
  selectedReferrals: [],
  // patient referral alerts and risks
  alerts: [],
  risks: [],
  predictions: [],
  // app errors/info/alert
  alert: {
    error: false,
    open: false,
    message: '',
  },
};

export default initialState;
