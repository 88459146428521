/**
 * Used to make sure all values are specified in a switch case,
 * or code is otherwise unreachable on compile.
 *
 * Can be used with enums or union types to assert exhaustiveness.
 *
 * @template
 * ```
 *  switch (value) {
 *    default:
 *      assertUnreachable(value);
 *  }
 * ```
 *
 * @export
 * @param {never} x
 * @return {*}  {never}
 */
export function assertUnreachable(x: never): never {
  throw new Error(`Should be unreachable: ${x}`);
}

/**
 * Used to make sure all values are specified in a switch case,
 * or code is otherwise unreachable on compile, without throwing an error at runtime.
 */
export function checkUnreachable(x: never) {}

/**
 * Gets the key from the given object where the value matches, or undefined if no value matches.
 *
 * Uses a `===` test to match on the value, so might not work on nested objects.
 *
 * @export
 * @param {T} obj a constant object with key-value properties
 * @param {*} value the value of the key to check for
 * @return {*} the key from object `obj` that the value matches, or undefined
 */
export function getKeyByValue<T extends {}>(obj: T, value: any) {
  const keys = Object.keys(obj) as Array<keyof T>;
  return keys.find((k) => obj[k] === value);
}

/**
 * Filter out any values that are 'falsy'
 * e.g. undefined, null, 0, '', false
 */
export function filterFalsy<T>(item: T | undefined | null): item is T {
  return !!item;
}

/** Filter out null and undefined */
export function filterNullUndefined<T>(item: T | undefined | null): item is T {
  return item != null;
}

/**
 * Helper function for turning const objects into a type.
 * ```
 * type YourType = AsType<typeof YourObject>
 * ```
 */
export type AsType<T extends { [K in keyof T]: T[K] }> = T[keyof T];

/**
 * Provides a function that checks if a given value is one of the values in the provided object
 * @param constObj - The object containing string values
 * @returns A type guard function that checks if the value is one of the values in the object
 * @example
 * const colors = {
 *   red: 'RED',
 *   green: 'GREEN',
 *   blue: 'BLUE',
 * };
 *
 * const isColor = isTypeConst(colors);
 *
 * console.log(isColor('RED')); // true
 * console.log(isColor('YELLOW')); // false
 */
export const isTypeConst =
  <T extends Record<string, string>>(constObj: T) =>
  (stringVal: string): stringVal is T[keyof T] =>
    Object.values(constObj).includes(stringVal as T[keyof T]);

export function isEmpty(object: { [key: string]: any }) {
  return Object.keys(object).length === 0;
}

/**
 * Maps optional set of keys to a type.
 *
 * Same as a Record, but with optional keys
 * @see Record
 */
export type PartialRecord<K extends keyof any, T> = {
  [P in K]?: T;
};

/**
 * Partition an array based on the given predicate
 *
 * @param {Array} arr The list to partition
 * @param {Function} predicate A predicate to determine which side the element belongs to
 * @returns {Array} A tuple containing the partitioned lists, with the affirmative elements at index 0
 *
 * @example
 *     // split into negative and non-negative numbers
 *     partition([1, 2, -4, -7, 4, 22], (n) => n < 0)
 *     // => [ [-4, -7], [1, 2, 4, 22] ]
 */
export function partition<T>(arr: T[], predicate: (entry: T) => boolean) {
  const partitioned: [T[], T[]] = [[], []];

  arr.forEach((entry: T) => {
    const partitionIndex = predicate(entry) ? 0 : 1;
    partitioned[partitionIndex].push(entry);
  });
  return partitioned;
}
