import { Divider, Grid, makeStyles, Tooltip } from '@material-ui/core';
import { TabPanel, ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import StatusHeading from '../../components/statusHeading';
import { assertUnreachable } from '../../lib/tsUtils';
import { ClinicianStatus, clinicianStatusNames } from '../../lib/types/clinicianStatus';
import Feature from '../../lib/types/feature';
import { ReferralAlert, referralAlertNames } from '../../lib/types/referralAlerts';
import { ReferralStatus, referralStatusNames } from '../../lib/types/referralStatus';
import { TestStatus, testStatusNames } from '../../lib/types/testStatus';
import {
  alertHoverDescriptions,
  clinicianHoverDescriptions,
  testHoverDescriptions,
  workflowHoverDescriptions,
} from './hoverDescriptions';
import {
  FilterOptionKey,
  tabOption,
  TabOptionData,
  TabOptionKey,
  tabOptionKeyNames,
} from './tabSelector';
import { RibbonFilterMap } from './types';

const useStyles = makeStyles((theme) => ({
  statusTitle: {
    fontSize: '0.875rem',
    marginLeft: theme.spacing(3),
    marginRight: theme.spacing(3),
    marginBottom: theme.spacing(1),
    textTransform: 'capitalize',
  },
  alerts: {
    marginLeft: 'auto',
    marginRight: theme.spacing(3),
  },
  toggleButton: {
    border: '1px solid rgba(0, 0, 0, 0.12) !important',
  },
  tabPanel: {
    padding: theme.spacing(3),
  },
  verticalDivider: {
    minHeight: '54px',
  },
}));

const getFilterLabel = (filterGroup: FilterOptionKey, key: string) => {
  switch (filterGroup) {
    case 'workflow':
      return referralStatusNames[key as ReferralStatus];
    case 'test':
      return testStatusNames[key as TestStatus];
    case 'clinician':
      return clinicianStatusNames[key as ClinicianStatus];
    case 'alerts':
      return referralAlertNames[key as ReferralAlert];
    default:
      return assertUnreachable(filterGroup);
  }
};

const getHoverDescription = (filterGroup: FilterOptionKey, key: string) => {
  switch (filterGroup) {
    case 'workflow':
      return workflowHoverDescriptions[key as ReferralStatus];
    case 'test':
      return testHoverDescriptions[key as TestStatus];
    case 'clinician':
      return clinicianHoverDescriptions[key as ClinicianStatus];
    case 'alerts':
      return alertHoverDescriptions[key as ReferralAlert];
    default:
      return assertUnreachable(filterGroup);
  }
};

type FilterButtonGroupProps = Readonly<{
  selectedFilters: RibbonFilterMap;
  onFilterChange: (value: string, filterGroupName: FilterOptionKey) => void;
  featureList: Feature[];
  tabOptions: Record<TabOptionKey, TabOptionData>;
}>;

function FilterButtonGroup({
  selectedFilters,
  onFilterChange,
  featureList,
  tabOptions = tabOption,
}: FilterButtonGroupProps) {
  const classes = useStyles();
  const postOpHqEnabled = featureList.includes(Feature.POST_OP_HQ);
  const endoscopyEnabled = featureList.includes(Feature.ENDOSCOPY);

  const getClassName = (filterGroup: FilterOptionKey) =>
    filterGroup === 'alerts' ? classes[filterGroup] : undefined;

  const isSelected = (filterGroup: FilterOptionKey, key: string) =>
    selectedFilters.get(filterGroup)?.includes(key);

  const isEnabled = (filterKey: string) =>
    (postOpHqEnabled ||
      (filterKey !== ReferralStatus.PostOpHQSent &&
        filterKey !== ReferralStatus.PostOpHQReceived)) &&
    (endoscopyEnabled || filterKey !== ReferralStatus.ReadyForBowelPrep);

  return (
    <>
      {Object.entries(tabOptions).map(([key, option]) => (
        <TabPanel value={key} key={key} className={classes.tabPanel}>
          <Grid container alignItems="center">
            {Object.entries(option.filter).map(([filterGroupName, filters], idx) => {
              const filterGroup = filterGroupName as FilterOptionKey;
              return (
                <>
                  {idx !== 0 && (
                    <Grid item>
                      <Divider orientation="vertical" className={classes.verticalDivider} />
                    </Grid>
                  )}
                  <Grid item className={getClassName(filterGroup)} style={{ marginBottom: '18px' }}>
                    <Grid container direction="column" spacing={1}>
                      <Grid item>
                        <StatusHeading className={classes.statusTitle}>
                          {tabOptionKeyNames[filterGroup]}
                        </StatusHeading>
                      </Grid>
                      <ToggleButtonGroup
                        exclusive
                        size="small"
                        onChange={(_: any, value: string) => onFilterChange(value, filterGroup)}
                      >
                        {filters.map(
                          (filterKey) =>
                            isEnabled(filterKey) && (
                              <Tooltip
                                key={filterKey}
                                title={getHoverDescription(filterGroup, filterKey)}
                                arrow
                              >
                                <ToggleButton
                                  value={filterKey}
                                  selected={isSelected(filterGroup, filterKey)}
                                  className={classes.toggleButton}
                                >
                                  {getFilterLabel(filterGroup, filterKey)}
                                </ToggleButton>
                              </Tooltip>
                            ),
                        )}
                      </ToggleButtonGroup>
                    </Grid>
                  </Grid>
                </>
              );
            })}
          </Grid>
        </TabPanel>
      ))}
    </>
  );
}

export default FilterButtonGroup;
